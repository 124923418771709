<template>
    <div class="employees position-relative overflow-hidden">
        <!-- шапка блока-->
        <div class="p-20 mb-20 bg-white border-bottom border-brd-primary position-relative">
            <div class="page-header">
                <h1 class="page-title">Сотрудники</h1>

                <div>
                    <div class="d-flex text-nowrap-mask scroller scroller-x pb-3 mb-10" role="tablist">
                        <NavTabs :navItems="this.tabItems"/>
                    </div>

                    <div 
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center"
                    >
                        <button 
                            class="btn d-flex align-items-center p-0 mb-2 mb-sm-0 me-20 rounded-pill"
                            data-bs-toggle="modal" data-bs-target="#addStaff"
                            style="min-width: 160px;"
                        >
                            <span
                                class=" d-flex align-items-center justify-content-center 
                                        bg-primary rounded-circle me-1
                                    "
                                style="width: 50px; height: 50px;"
                            >
                                <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">

                            </span>
                            <span class="text-font-dark fw-semi font-semi fs-4 px-2">Новый агент</span>
                        </button>

                        <div class="input-group w-100 w-sm-50 flex-grow-1">
                            <input type="text" class="form-control rounded-end rounded-1" placeholder="Имя, Название, Email или Телефон" aria-label="Поиск" aria-describedby="button-addon2">
                            
                            <button type="button" aria-label="Поиск" id="button-addon2" class="crm-btn btn bg-primary rounded-start rounded-1">
                                <img :src="require('@/assets/icons/search-white-icon.svg')" alt="Поиск" width="18" height="18">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- шапка блока конец-->

        <!--контент-->
        <div class="tab-content">
            <div 
                class="tab-pane fade show active"
                id="active" 
                role="tabpanel" 
                aria-labelledby="Вкладка активные сотрудники"
            >
                <StaffList 
                    :staffList="this.employeeList.filter(emp => emp.isBlocked !== true)"
                    :stafferStatus="'active'"
                />
            </div>

            <div 
                class="tab-pane fade" 
                id="online" 
                role="tabpanel" 
                aria-labelledby="Вкладка cотрудники online "
            >
                <StaffList 
                    :staffList="this.employeeList.filter(emp => emp.isOnline === true)"
                    :stafferStatus="'online'"
                />
            </div>

            <div 
                class="tab-pane fade" 
                id="blocked" 
                role="tabpanel" 
                aria-labelledby="Вкладка заблокированные cотрудники"
            >
                <StaffList 
                    :staffList="this.employeeList.filter(emp => emp.isBlocked === true)"
                    :stafferStatus="'blocked'"
                />
            </div>
        </div>
        <!--контент конец-->

        <StaffEditModal
            :modalType="'addStaff'"
            @updateState="updateState"
        />
    </div>
</template>

<script>
    import CommonService from "../services/CommonService";
    import NavTabs from '@/components/common/NavTabs'
    import StaffList from '@/components/employees/StaffList'
    import StaffEditModal from '@/components/modals/StaffEditModal'
    
    export default {
        name: "Employees",

        components: {
            NavTabs,
            StaffList,
            StaffEditModal
        },

        data() {
            return {
                employees: [],
                tabItems: [
                    {label: "Активные", title: "Активные", count: null, id: 'active-tab', data_bs_toggle:'tab', data_bs_target: '#active', active: true, href: '#', action: () => console.log('Tab 1 clicked!')},
                    {label: "Online", title: "Online", count: null, id: 'online-tab', data_bs_toggle:'tab', data_bs_target: '#online', active: false, href: '#', action: () => console.log('Tab 2 clicked!')},
                    {label: "Заблок-нные", title: "Заблок-нные", count: null, id: 'blocked-tab', data_bs_toggle:'tab', data_bs_target: '#blocked', active: false, href: '#', action: () => console.log('Tab 3 clicked!')},
                ],
            };
        },

        computed: {
            employeeList() {
                return this.$store.getters.employeeList
            }
        },

        methods: {
            updateState(data) {
                this.$store.dispatch('update_employees', JSON.stringify(data));
                this.tabInit();
            },

            init() {
                this.$store.dispatch('get_employees');
                this.tabInit();
            },

            tabInit() {
                console.log('test tabs')
                this.tabItems.forEach(tab => {
                    tab.id === 'active-tab' ?
                    tab.count = this.$store.getters.activeEmployees.length :

                    tab.id === 'online-tab' ?
                    tab.count = this.$store.getters.onlineEmployees.length :

                    tab.id === 'blocked-tab' ?
                    tab.count = this.$store.getters.blockedEmployees.length : null
                })
            }
        },

        mounted() {
            this.init();
        }

        /*computed: {
            employeeList () {
                return this.$store.getters.employeeList
            },
        },
        methods: {
            updateState(data) {
                console.log('update state', data)
                this.$store.dispatch('edit_employeer', data)
                this.init()
            },

            init() {
                setTimeout(() => {
                    this.$store.dispatch('get_employees');
                }, 200);
                
                this.tabInit()
            },

            tabInit() {
                console.log('test tabs')
                this.tabItems.forEach(tab => {
                    tab.id === 'active-tab' ?
                    tab.count = this.$store.getters.activeEmployees.length :

                    tab.id === 'online-tab' ?
                    tab.count = this.$store.getters.onlineEmployees.length :

                    tab.id === 'blocked-tab' ?
                    tab.count = this.$store.getters.blockedEmployees.length : null
                })
            },
        },

        mounted() {
            CommonService.setPageTitle(this.title);
            this.init();
        }, */
    };
</script>