<template>
    <div 
        class="modal fade" 
        :id="this.$props.modalType === 'addStaff' ? this.$props.modalType : this.$props.modalType + this.$props.stafferData.id" tabindex="-1" aria-labelledby="Действие над сотрудником" aria-hidden="true">    >
        <div class="modal-dialog">
            <div class="modal-content rounded-1 p-20">
                <div class="modal-header d-flex align-items-center border-0 p-0 pb-20">
                    <span class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">
                        {{
                            this.$props.modalType === 'editStaff' && this.$props.stafferData.rank === 'Агент' ? 'Редактировать агента' :
                            this.$props.modalType === 'editStaff' && this.$props.stafferData.rank === 'Менеджер' ? 'Редактировать менеджера' :
                            this.$props.modalType === 'addStaff' ? 'Новый агент': null
                        }}
                    </span>
                    <button 
                        type="button" 
                        class="d-flex ms-auto btn-close"
                        data-bs-dismiss="modal" aria-label="Close"
                    ></button>
                </div>

                <div class="modal-body border-0 p-0 mb-3 rounded-0">
                    <!--tabs-->
                    <div class="border-bottom border-brd-primary">
                        <ul 
                            class="d-flex flex-column flex-sm-row flex-sm-wrap align-items-start align-items-sm-center m-0 p-0"
                        >
                            <li class="nav-item d-flex align-items-center mb-10 w-50">
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    
                                    @click="this.tabNum = 1"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 1 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/info-card-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 1"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/info-card-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 1"
                                        >

                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="tabNum === 1 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        Информация
                                    </span>
                                </button>
                            </li>

                            <li class="nav-item d-flex align-items-center mb-10 w-50">
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    
                                    @click="this.tabNum = 2"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 2 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/passport-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 2"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/passport-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 2"
                                        >

                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="tabNum === 2 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        Паспорт
                                    </span>
                                </button>
                            </li>

                            <li class="nav-item d-flex align-items-center mb-10 w-50">
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    
                                    @click="this.tabNum = 3"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 3 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/connect-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 3"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/connect-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 3"
                                        >

                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="tabNum === 3 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        Соц. сети
                                    </span>
                                </button>
                            </li>

                            <li class="nav-item d-flex align-items-center mb-10 w-50">
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                                    
                                    @click="this.tabNum = 4"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 
                                            "
                                        :class="this.tabNum === 4 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        <img 
                                            :src="require('@/assets/icons/blank-gray-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum !== 4"
                                        >

                                        <img 
                                            :src="require('@/assets/icons/blank-green-icon.svg')" 
                                            alt="Информация" width="24" height="24"
                                            v-if="this.tabNum === 4"
                                        >

                                    </span>
                                    <span 
                                        class="fw-semi font-semi fs-4 px-2"
                                        :class="tabNum === 4 ? 'text-success' : 'text-font-secondary'"
                                    >
                                        Документы
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!--end tabs-->

                    <div class="pt-20" v-if="tabNum === 1">
                        <div class="mb-3">
                            <span class="text-font-secondary d-block mb-3">Аватар</span>
                            
                            <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center mb-3">
                                <img 
                                    :src="require('@/assets/img/user_avatar.png')" alt="Аватар" width="80" height="80" 
                                    class="me-10"
                                >

                                <div class="d-flex align-items-center">
                                    <img :src="require('@/assets/icons/mountains-icon.svg')" alt="Документ" width="20" height="20" class="me-10">
                                    <span class="me-10 fs-4">КП Сокращенное.docx</span>
                                    <button type="button" class="btn">
                                        <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24">
                                    </button>
                                </div>                         
                            </div>

                            <div class="mb-3">
                                <label for="formFile" class="form-label bg-light-gray px-20 py-3 rounded-3 border border-brd-primary">
                                    <img :src="require('@/assets/icons/staple-icon.svg')" alt="Прикрепить файл" width="18" height="18" class="me-10">
                                    <span class="text-font-secondary font-semi fw-semi">Выберите файл</span>
                                </label>
                                <input class="form-control bg-light-gray visually-hidden" type="file" id="formFile">
                            </div>
                        </div>

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_surname'"
                            :inputName="'staffer_surname'"
                            :inputLabel="'Фамилия'"
                            :inputPlaceholder="this.staffer.surname"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                            @setData="setData"
                        />

                        <InputText  
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_name'"
                            :inputName="'staffer_name'"
                            :inputLabel="'Имя'"
                            :inputPlaceholder="this.staffer.name"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                            @setData="setData"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_patronymic'"
                            :inputName="'staffer_patronymic'"
                            :inputLabel="'Отчество'"
                            :inputPlaceholder="this.staffer.patronymic"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                            @setData="setData"
                        />
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_email'"
                            :inputName="'staffer_email'"
                            :inputLabel="'Эл. почта'"
                            :inputPlaceholder="this.staffer.email"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                            @setData="setData"
                        />
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_birthday'"
                            :inputName="'staffer_birthday'"
                            :inputLabel="'День рождения'"
                            :inputPlaceholder="this.staffer.birthDay"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                            @setData="setData"
                        />
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_phone'"
                            :inputName="'staffer_phone'"
                            :inputLabel="'Телефон'"
                            :inputPlaceholder="this.staffer.phone"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                            @setData="setData"
                        />

                        <Switcher
                            :inputId="'staffer_isPhoneEqualIpPhone'"
                            :inputName="this.staffer.id + 'staffer_isPhoneEqualIpPhone'"
                            :inputLabel="'Номер телефона совпадает с IP-телефоном'"
                            :inputLabelClass="'form-check-label text-font-secondary'"
                            :inputClass="'p-0'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_IPphone'"
                            :inputName="'staffer_IPphone'"
                            :inputLabel="'IP-телефон'"
                            :inputPlaceholder="this.staffer.IPphone"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                            @setData="setData"
                        />
                    </div>

                    <div class="pt-20" v-if="tabNum === 2">
                        <div class="mb-3">
                            <label class="d-block text-font-secondary mb-3" for="this.staffer.id + 'passportNum'">Серия/номер паспорта</label>

                            <div class="input-group mb-3">
                                <input type="text" id="this.staffer.id + 'passportNum'" class="form-control rounded-1 me-10 w-25" placeholder="Серия" aria-label="Серия паспорта">
                                <input type="text" class="form-control rounded-1 w-50" placeholder="Номер" aria-label="Номер паспорта">
                            </div>
                        </div>

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_passportDate'"
                            :inputName="'staffer_passportDate'"
                            :inputLabel="'Дата выдачи'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_passportWho'"
                            :inputName="'staffer_passportWho'"
                            :inputLabel="'Кем выдан'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_passportRegAddress'"
                            :inputName="'staffer_passportRegAddress'"
                            :inputLabel="'Адрес регистрации'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_passportPostAddress'"
                            :inputName="'staffer_passportPostAddress'"
                            :inputLabel="'Почтовый адрес'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />
                    </div>

                    <div class="pt-20" v-if="tabNum === 3">
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_vkAccount'"
                            :inputName="'staffer_vkAccount'"
                            :inputLabel="'ВКонтакте'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_tgAccount'"
                            :inputName="'staffer_tgAccount'"
                            :inputLabel="'Telegram'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_fbAccount'"
                            :inputName="'staffer_fbAccount'"
                            :inputLabel="'Facebook'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_instaAccount'"
                            :inputName="'staffer_instaAccount'"
                            :inputLabel="'Instagram'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_twAccount'"
                            :inputName="'staffer_twAccount'"
                            :inputLabel="'Twitter'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />
                    </div>

                    <div class="pt-20" v-if="tabNum === 4">
                        <InputText 
                            :inputLabelClass="'text-font-secondary mb-3'"
                            :inputId="this.staffer.id + 'staffer_docName'"
                            :inputName="'staffer_docName'"
                            :inputLabel="'Название документа'"
                            :inputClass="'rounded-1'"
                            :inputWrapClass="'mb-3'"
                        />

                        <div class="mb-2">
                            <label for="formFile" class="form-label bg-light-gray px-20 py-3 rounded-3 border border-brd-primary">
                                <img :src="require('@/assets/icons/staple-icon.svg')" alt="Прикрепить файл" width="18" height="18" class="me-10">
                                <span class="text-font-secondary font-semi fw-semi">Выберите файл</span>
                            </label>
                            <input class="form-control bg-light-gray visually-hidden" type="file" id="formFile">
                        </div>

                        <div class="d-flex align-items-center mb-3">
                            <img :src="require('@/assets/icons/blank-icon.svg')" alt="Документ" width="18" height="18" class="me-10">

                            <span class="me-10 fs-4">Название документа.docx</span>

                            <button type="button" class="btn">
                                <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24">
                            </button>
                        </div>

                        <div class="d-flex align-items-center">
                            <button 
                                class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
                                style="min-width: 150px;"
                            >
                                <span
                                    class=" d-flex align-items-center justify-content-center 
                                            bg-primary rounded-circle me-1
                                        "
                                    style="width: 50px; height: 50px;"
                                >
                                    <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">

                                </span>
                                <span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить файл</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="modal-footer border-0 p-0">
                    <button 
                        type="button" 
                        class="btn btn-primary col-12 rounded-3 m-0 py-3 text-white fs-3" 
                        data-bs-dismiss="modal"
                        @click="this.saveData()"
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from '@/components/inputs/InputText'
    import Switcher from '@/components/inputs/Switcher'

    export default {
        name: "StassEditModal",

        components: {
            InputText,
            Switcher,
        },

        props: {
            modalType:      { type: String },
            stafferData:    { type: Object }
        },

        data() {
            return {
                tabNum: 1,
                staffer: (typeof this.stafferData !== "undefined") ? this.stafferData : {},
                employeerData: {}
            }
        },

        methods: {
            saveData() {
                console.log('save', this.employeerData)
                this.$emit('updateState', this.employeerData)
            },

            setData(payload) {
                console.log('setdata', payload)
                this.$props.modalType === 'editStaff' ?
                this.employeerData.id = this.$props.stafferData.id : null
                

                payload.name === 'staffer_surname' ?
                this.employeerData.surname = payload.value :

                payload.name === 'staffer_name' ?
                this.employeerData.name = payload.value :

                payload.name === 'staffer_patronymic' ?
                this.employeerData.patronymic = payload.value :

                payload.name === 'staffer_email' ?
                this.employeerData.email = payload.value :

                payload.name === 'staffer_phone' ?
                this.employeerData.phone = payload.value :

                payload.name === 'staffer_birthday' ?
                this.employeerData.phone = payload.value :

                payload.name === 'staffer_IPphone' ?
                this.employeerData.IPphone = payload.value : null
                
            }
        }
    }
</script>