<template>
    <div class="bg-white rounded-3">
        <!--шапка-->
        <div class="p-20 pb-0">
            <div class="border-bottom border-brd-primary">
                <div class="d-flex align-items-start">
                    <div class="me-auto" style="max-width: 80%;">
                        <div class="d-flex flex-wrap mb-20">
                            <span 
                                class="me-20 fs-5"
                                :class="
                                    this.$props.stafferData.rank === 'Агент' ? 'text-secondary'  :
                                    this.$props.stafferData.rank === 'Менеджер' ? 'text-primary' : null
                                "
                            >{{this.$props.stafferData.rank}}</span>
                            <span class="text-secondary me-20 fs-5">Регистрация: {{this.$props.stafferData.published_at}}</span>
                        </div>

                        <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center mb-20">
                            <button 
                                type="button" 
                                class="
                                        d-flex align-items-center btn bg-light-gray 
                                        text-secondary border border-brd-primary rounded-1 
                                        font-semi fw-semi position-relative p-10 px-3 me-10
                                        fs-5 text-font-secondary mb-10 mb-sm-0
                                    "
                            >
                                <img :src="require('@/assets/icons/note-icon.svg')" alt="Настройка" width="14" height="14" class="me-1">
                                {{this.$props.stafferData.company}}
                            </button>

                            <span 
                                class="d-block border rounded-1 fs-5 font-semi fw-semi p-10 px-3"
                                :class="this.$props.stafferData.isOnline ? 'border-success text-success' : 'border-raspberry text-raspberry'"
                            >
                                {{ this.$props.stafferData.isOnline ? 'online' : this.$props.stafferData.last_visit}}
                            </span>
                        </div>

                        <div class="d-flex flex-column mb-3">
                            <span 
                                class="crm-underline fs-3 fw-semi font-semi" 
                                style="max-width: 300px; cursor: pointer;"
                                @click="isDetailInfoOpened = !isDetailInfoOpened, this.initPopovers()"
                            >
                                {{this.$props.stafferData.fullName}}
                            </span>
                        </div>
                    </div>

                    <div class="d-flex flex-column">
                        <button 
                            type="button" 
                            class="btn mb-2"
                            data-bs-toggle="modal" :data-bs-target="'#editStaff' + this.$props.stafferData.id"
                        > 
                            <img :src="require('@/assets/icons/options-icon.svg')" alt="Настройка" width="24" height="24">
                        </button>
                        
                        <button 
                            type="button" class="btn mb-2"
                            data-bs-toggle="modal" :data-bs-target="'#' + this.confirmModalState.fromSection + this.$props.stafferData.id"
                            @click="fillConfirmModalState('deleteStaffer')"
                        >
                            <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24">
                        </button>

                        <button
                            :disabled="this.$props.status !== 'blocked' ? true : false"
                            v-if="this.$props.stafferData.isBlocked"
                            type="button" class="btn mb-2"
                            data-bs-toggle="modal" :data-bs-target="'#' + this.confirmModalState.fromSection + this.$props.stafferData.id"
                            @click="fillConfirmModalState('unblockStaffer')"
                        >
                            <img :src="require('@/assets/icons/blocked-red-icon.svg')" alt="Отменить блокировку" width="24" height="24">
                        </button>

                        <button
                            v-else
                            type="button" class="btn mb-2"
                            data-bs-toggle="modal" :data-bs-target="'#' + this.confirmModalState.fromSection + this.$props.stafferData.id"
                            @click="fillConfirmModalState('blockStaffer')"
                        >
                            <img :src="require('@/assets/icons/blocked-icon.svg')" alt="Заблокировать" width="24" height="24">
                        </button>
                    </div>
                </div>

                <ul 
                    class="d-flex popover-container flex-column flex-sm-row align-items-start align-items-sm-center m-0 p-0 position-relative"
                    v-if="isDetailInfoOpened"
                >
                    <li class="nav-item d-flex align-items-center mb-10 me-20">
                        <button 
                            class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                            data-bs-toggle="popover"
                            :data-bs-target="'#staffer-passport' + this.stafferData.id"
                            data-bs-placement="bottom"
                            data-bs-trigger="focus"
                            @click="this.activeBtn = 1"
                        >
                            <span
                                class=" d-flex align-items-center justify-content-center 
                                        rounded-circle me-1
                                    "
                                :class="this.activeBtn === 1 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                style="width: 40px; height: 40px; border-width: 2px;"
                            >
                                <img 
                                    :src="require('@/assets/icons/passport-icon.svg')" 
                                    alt="Информация" width="24" height="24"
                                    v-if="this.activeBtn !== 1"
                                >

                                <img 
                                    :src="require('@/assets/icons/passport-green-icon.svg')" 
                                    alt="Информация" width="24" height="24"
                                    v-if="this.activeBtn === 1"
                                >
                            </span>
                            <span 
                                class="fw-semi font-semi fs-4 px-2"
                                :class="activeBtn === 1 ? 'text-success' : 'text-font-secondary'"
                            >
                                Паспорт
                            </span>
                        </button>

                        <div class="d-none">
                            <div
                                :id="'staffer-passport' + this.stafferData.id" 
                                class="rounded-3 p-0"
                            >
                                <ul class="m-0 p-0 p-3 ">
                                    <li class="d-flex align-items-start justify-content-between mb-3">
                                        <span class="d-block text-font-secondary fs-4 w-50">Номер серия:</span>
                                        <span class="d-block fs-4 w-50">{{this.$props.stafferData.passport.number}}</span>
                                    </li>

                                    <li class="d-flex align-items-start justify-content-between mb-3">
                                        <span class="d-block text-font-secondary fs-4 w-50">Дата выдачи:</span>
                                        <span class="d-block fs-4 w-50">{{this.$props.stafferData.passport.date}}</span>
                                    </li>

                                    <li class="d-flex align-items-start justify-content-between mb-3">
                                        <span class="d-block text-font-secondary fs-4 w-50">Кем выдан:</span>
                                        <span class="d-block fs-4 w-50">{{this.$props.stafferData.passport.department}}</span>
                                    </li>

                                    <li class="d-flex align-items-start justify-content-between mb-3">
                                        <span class="d-block text-font-secondary fs-4 w-50">Адрес регистрации:</span>
                                        <span class="d-block fs-4 w-50">{{this.$props.stafferData.passport.address}}</span>
                                    </li>

                                    <li class="d-flex align-items-start justify-content-between mb-3">
                                        <span class="d-block text-font-secondary fs-4 w-50 pe-10">Почтовый адрес:</span>
                                        <span class="d-block fs-4 w-50">{{this.$props.stafferData.passport.postAddress}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item d-flex align-items-center mb-10 me-20">
                        <button 
                            class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                            data-bs-toggle="popover"
                            :data-bs-target="'#staffer-social' + this.stafferData.id"
                            data-bs-placement="bottom"
                            data-bs-trigger="focus"
                            @click="this.activeBtn = 2"
                        >
                            <span
                                class=" d-flex align-items-center justify-content-center 
                                        rounded-circle me-1
                                    "
                                :class="this.activeBtn === 2 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                style="width: 40px; height: 40px; border-width: 2px;"
                            >
                                <img 
                                    :src="require('@/assets/icons/connect-icon.svg')" 
                                    alt="Информация" width="24" height="24"
                                    v-if="this.activeBtn !== 2"
                                >

                                <img 
                                    :src="require('@/assets/icons/connect-green-icon.svg')" 
                                    alt="Информация" width="24" height="24"
                                    v-if="this.activeBtn === 2"
                                >
                            </span>
                            <span 
                                class="fw-semi font-semi fs-4 px-2"
                                :class="activeBtn === 2 ? 'text-success' : 'text-font-secondary'"
                            >
                                Соц.сети
                            </span>
                        </button>

                        <div class="d-none">
                            <div
                                :id="'staffer-social' + this.stafferData.id" 
                                class="rounded-3 p-0"
                            >
                                <ul class="m-0 p-0 p-3" style="min-width: 280px;">
                                    <li class="mb-20"
                                        v-for="(soc, i) of this.$props.stafferData.socials"
                                        :key="'soc_' + i"
                                    >
                                        <a :href=soc.url >
                                            <img :src="require('@/assets/img/vk-icon.png')" :alt=soc.name width="40" height="40" class="me-10">

                                            <span class="crm-underline text-font-primary">{{soc.name}}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item d-flex align-items-center mb-10 me-20"
                        v-if="this.$props.stafferData.docs"
                    >
                        <button 
                            class="btn btn-outline d-flex align-items-center p-0 rounded-pill"
                            data-bs-toggle="popover"
                            :data-bs-target="'#staffer-docs' + this.stafferData.id"
                            data-bs-placement="bottom"
                            data-bs-trigger="focus"
                            @click="this.activeBtn = 3"
                        >
                            <span
                                class=" d-flex align-items-center justify-content-center 
                                        rounded-circle me-1
                                    "
                                :class="this.activeBtn === 3 ? ' border border-success' : 'border border-brd-primary bg-light-gray' "
                                style="width: 40px; height: 40px; border-width: 2px;"
                            >
                                <img 
                                    :src="require('@/assets/icons/blank-gray-icon.svg')" 
                                    alt="Информация" width="24" height="24"
                                    v-if="this.activeBtn !== 3"
                                >

                                <img 
                                    :src="require('@/assets/icons/blank-green-icon.svg')" 
                                    alt="Информация" width="24" height="24"
                                    v-if="this.activeBtn === 3"
                                >
                            </span>
                            
                            <span 
                                class="fw-semi font-semi fs-4 px-2"
                                :class="activeBtn === 3 ? 'text-success' : 'text-font-secondary'"
                            >
                                Документы
                            </span>
                        </button>

                        <div class="d-none">
                            <div
                                :id="'staffer-docs' + this.stafferData.id" 
                                class="rounded-3 p-0"
                            >
                                <ul class="m-0 p-0 p-3" style="min-width: 280px;">
                                    <li class="mb-20"
                                        v-for="(doc, i) of this.$props.stafferData.docs"
                                        :key="'doc_' + i"
                                    >
                                        <img :src="require('@/assets/icons/blank-icon.svg')" alt=doc.title width="18" height="18" class="me-10">

                                        <span class="crm-underline text-font-primary">{{ doc.title }}</span>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!--шапка конец-->

        <!--контент-->
        <div 
            class="p-20 pb-0"
        >
            <div>
                <div class="d-flex align-items-center mb-3">
                    <div class="d-flex flex-column w-50">
                        <span class="fs-5 text-font-secondary">Клиенты</span>
                        <a 
                            href="#" 
                            class="text-black crm-underline"
                        >
                            {{this.$props.stafferData.clientsLength}}
                        </a>
                    </div>

                    <div class="d-flex flex-column w-50">
                        <span class="fs-5 text-font-secondary">Объекты</span>
                        <a 
                            href="#" 
                            class="text-black crm-underline"
                        >
                            {{this.$props.stafferData.objectsLength}}
                        </a>
                    </div>
                </div>

                <div v-if="isDetailInfoOpened">
                    <div class="d-flex flex-column mb-3">
                        <span class="fs-5 text-font-secondary mb-1">Телефон</span>
                        <span>{{this.$props.stafferData.phone}}</span>
                    </div>
                    <div class="d-flex flex-column mb-3">
                        <span class="fs-5 text-font-secondary mb-1">Эл. почта</span>
                        <a :href="'mailto:' + this.$props.stafferData.email" class="text-font-primary crm-underline">{{this.$props.stafferData.email}}</a>
                    </div>
                </div>
            </div>

            <div
                v-if="isDetailInfoOpened"
                class="pt-20"
                :class="
                        this.$props.stafferData.rank === 'Менеджер' && this.$props.stafferData.isBlocked ? 
                        'border-0' : 'border-bottom border-brd-primary'
                    "
            >
                <div class="mb-3" v-if="this.$props.stafferData.rank === 'Менеджер'">
                    <button 
                        type="button" 
                        class="btn bg-light-gray text-secondary border border-brd-primary rounded-1 font-semi fw-semi px-20 py-10"
                    >
                        Разжаловать
                    </button>
                </div>

                <div class="mb-3">
                    <InputSelect
                        :inputLabel="'Роль в отделе'"
                        :inputLabelClass="'d-block text-font-secondary mb-3'"
                        :inputId="'staffer_manager'"
                        :inputName="'staffer_manager'"
                        :inputOptions="{
                            1: 'Агент',
                            2: 'Менеджер'
                        }"
                        :inputClass="'p-3 rounded-1 fs-4'"
                    />
                </div>

                <div v-if="this.$props.stafferData.rank === 'Агент'" class="mb-20">
                    <InputSelect
                        :inputLabel="'Менеджер'"
                        :inputLabelClass="'d-block text-font-secondary mb-3'"
                        :inputId="'staffer_manager'"
                        :inputName="'staffer_manager'"
                        :inputOptions="{
                            1: 'Иван',
                            2: 'Ваня'
                        }"
                        :inputClass="'p-3 rounded-1 fs-4'"
                    />
                </div>
            </div>
        </div>

        <!--футер-->
        <div v-if="isDetailInfoOpened">
            <div 
                v-if="this.$props.stafferData.rank === 'Агент'"
                class="d-flex align-items-center px-20 py-10"
            >
                <a 
                    href="#"
                    class="d-flex align-items-center py-10 w-50"
                >
                    <img :src="require('@/assets/icons/strategy-icon.svg')" alt="Отключить" width="24" height="24" class="me-10">
                    <span 
                        class="fs-4 text-font-primary me-auto"
                    >
                        План продаж
                    </span>
                </a>

                <a 
                    href="#"
                    class="d-flex align-items-center py-10 w-50"
                >
                    <img :src="require('@/assets/icons/phone-call-icon.svg')" alt="Отключить" width="24" height="24" class="me-10">
                    <span 
                        class="fs-4 text-font-primary me-auto"
                        style="cursor: pointer"
                    >
                        Рекламные телефоны
                    </span>
                </a>
            </div>

            <div
                v-if="this.$props.stafferData.rank === 'Менеджер' && !this.$props.stafferData.isBlocked"
            >
                <div 
                    class="d-flex align-items-center px-20 py-10"
                    style="height: 54px;"
                >
                    <img :src="require('@/assets/icons/staff-icon.svg')" alt="Отключить" width="24" height="24" class="me-10">
                    <span 
                        class="fs-4 text-font-primary me-auto"
                        :class="!isStaffListOpened ? 'crm-underline' : null"
                        @click="isStaffListOpened = true"
                        style="cursor: pointer"
                    >
                        Агенты менеджера
                    </span>
                    <button
                        v-if="isStaffListOpened"
                        type="button" 
                        class="btn bg-light-gray text-secondary border border-brd-primary rounded-3 font-semi fw-semi"
                        @click="isStaffListOpened = false"
                    >
                        Свернуть
                    </button>
                </div>

                <div 
                    class="p-10 bg-light-gray border-top border-brd-primary"
                    v-if="isStaffListOpened"
                >
                    <ul class="m-0 p-0">
                        <li
                            v-for="(emp, i) of this.$props.stafferData.staffList"
                            :key="'emp_' + i"
                            class="border border-brd-primary rounded-1 bg-white pb-0 mb-20"
                        >
                            <StafferCard 
                                :stafferData="emp"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <ConfirmModal
            :id="this.$props.stafferData.id"
            :action="this.confirmModalState.action"
            :title="this.confirmModalState.title"
            :text="this.confirmModalState.text"
            :recipient="this.confirmModalState.recipient"
            :fromSection="this.confirmModalState.fromSection"
            :btnText="this.confirmModalState.btnText"
            @confirmed="setStafferOptions"
        />

        <StaffEditModal
            :modalType="'editStaff'"
            :stafferData="this.$props.stafferData"
        />

    </div>
</template>

<script>
    import ConfirmModal from '@/components/modals/ConfirmModal'
    import StaffEditModal from '@/components/modals/StaffEditModal'
    import InputSelect from '@/components/inputs/InputSelect'
    import CommonService from "@/services/CommonService";

    export default {
        components: {
            ConfirmModal,
            StaffEditModal,
            InputSelect
        },

        props: {
            stafferData : { type: Object },
            status: { type: String }
        },

        data() {
            return {
                isDetailInfoOpened: false,
                isStaffListOpened: false,
                activeBtn: 1,

                confirmModalState: {
                    action: '',
                    title: '',
                    text: '',
                    recipient: '',
                    fromSection: 'stafferCard',
                    btnText: ''
                }
            }
        },

        methods: {
            setStafferOptions(payload) {
                console.log('test', payload)
                payload.action === 'blockStaffer' ?
                this.$store.dispatch('blockStaffer', payload.id) :
                payload.action === 'deleteStaffer' ?
                this.$store.dispatch('deleteStaffer', payload.id) : null

            },
            
            fillConfirmModalState(str) {
                if(str === 'blockStaffer') {
                    this.confirmModalState.action = 'blockStaffer'
                    this.confirmModalState.title = "Добавить в черный список"
                    this.confirmModalState.text = "Разблокировать пользователя возможно в разделе заблокированные."
                    this.confirmModalState.recipient = this.$props.stafferData.fullName
                    this.confirmModalState.btnText = "Заблокировать"
                }

                if(str === 'unblockStaffer') {
                    this.confirmModalState.action = 'unblockStaffer'
                    this.confirmModalState.title = "Разблокировать пользователя"
                    this.confirmModalState.text = "Подтвердите действие разблокировки пользователя"
                    this.confirmModalState.recipient = this.$props.stafferData.fullName
                    this.confirmModalState.btnText = "Разблокировать"
                }

                if(str === 'deleteStaffer') {
                    this.confirmModalState.action = 'deleteStaffer'
                    this.confirmModalState.title = "Удалить пользователя"
                    this.confirmModalState.text = "Подтвердите действие удаления пользователя"
                    this.confirmModalState.recipient = this.$props.stafferData.fullName
                    this.confirmModalState.btnText = "Удалить"
                }
            },

            initPopovers() {
                setTimeout(() => { 
                    let containers = document.querySelectorAll('.popover-container')
                    let selector   = '[data-bs-toggle="popover"]'

                    containers.forEach(container => {
                        CommonService.popoversInit(container, selector)
                    })
                }, 100);
            }
        },
    }
</script>

<style lang="scss">
    /*$popover-border-color: #4Caf50;

    .popover {
        border-color: $popover-border-color !important;

        &-arrow {
            &::before {
                border-bottom-color: $popover-border-color !important;
            }
        }
    }*/
</style>