<template>
    <ul class="m-0 p-0">
         <li
             v-for="(emp, i) of this.$props.staffList"
             :key="'emp_' + i"
             class="border border-brd-primary rounded-1 bg-white pb-0 mb-20"
         >
             <StafferCard 
                 :stafferData="emp"
                 :status="this.$props.stafferStatus"
             />
         </li>
    </ul>
    <!-- <Pagination :itemsList="[]"/> -->
 </template>
 
 <script>
     import StafferCard from '@/components/employees/StafferCard'
     // import Pagination from '@/components/common/Pagination'
 
     export default {
         name: 'PartnerList',
 
         components: {
            StafferCard,
        //    Pagination
         },
 
        props: {
            staffList:    { type: Array },
            stafferStatus: { type: String }
        },

         watch: {
            staffList: function() {
                console.log('update staffList')
            }
         }
     }
 </script>